import React from 'react'
import HireUs from './hire-us'
import facebook from '../assets/images/social-media/white/facebook.svg'
import twitter from '../assets/images/social-media/white/twitter.svg'
import instagram from '../assets/images/social-media/white/instagram.svg'
import linkedin from '../assets/images/social-media/white/linkedin.svg'
import medium from '../assets/images/social-media/white/medium.svg'
import dribbble from '../assets/images/social-media/white/dribbble.svg'

function Footer() {
  return (
    <React.Fragment>
      <HireUs />
      <footer
        className="site-footer"
        aria-label="site footer"
      >
        <div className="footer-container">
          <div className="navigation-block footer-block">
            <h3>Navigation</h3>
            <span className="header-border"></span>
            <ul>
              <li>
                <a href="https://launchpadlab.com/">Home</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/about">About</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/work">Work</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/blog">Blog</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/industries">Industries</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/careers">Careers</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/resources">Resources</a>
              </li>
              <li>
                <a href="https://launchpadlab.com/privacy">Privacy</a>
              </li>
            </ul>
          </div>

          <div className="technologies-block footer-block">
            <h3>Technologies</h3>
            <span className="header-border"></span>
            <ul>
              <li>
                <a href="https://launchpadlab.com/technologies/ruby-on-rails/">
                  Ruby on Rails
                </a>
              </li>
              <li>
                <a href="https://launchpadlab.com/technologies/react-js/">
                  React.JS
                </a>
              </li>
              <li>
                <a href="https://launchpadlab.com/technologies/heroku/">
                  Heroku
                </a>{" "}
              </li>
              <li>
                <a href="https://launchpadlab.com/technologies/iot/">
                  IoT
                </a>
              </li>
              <li>
                <a href="https://launchpadlab.com/technologies/ionic/">
                  Ionic
                </a>
              </li>
              <li>
                <a href="https://launchpadlab.com/technologies/hipaa-compliance/">
                  HIPPA Compliance
                </a>
              </li>
            </ul>
          </div>

          <div className="services-block footer-block">
            <h3>Core Services</h3>
            <span className="header-border"></span>
            <ul>
              <li>
                <a href="http://launchpadlab.com/services#web">
                  Web Design &amp; Development
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#mobile">
                  Mobile Application Development
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#ui">
                  UX &amp; UI Design
                </a>
              </li>
              <li>
                <a href="http://launchpadlab.com/services#consulting">
                  Technology Strategy &amp; Consulting
                </a>
              </li>
            </ul>
          </div>

          <div className="contact-us-block footer-block">
            <h3>Work Together</h3>
            <span className="header-border"></span>
            <ul>
              <li>LaunchPad Lab</li>
              <li>220 W. Huron Street, Suite 2001<br></br>Chicago, Illinois 60654</li>
              <li><br></br></li>
              <li>Mailing Address:</li>
              <li>2045 W Grand Ave Ste B<br></br>PMB 37406<br></br>Chicago, Illinois 60612-1577 US</li>
              <li><br></br></li>
              <li>
                <a href="tel:1-773-270-1033">773-270-1033</a>
              </li>
              <li>
                <a href="mailto:contact@launchpadlab.com">
                  contact@launchpadlab.com
                </a>
              </li>
            </ul>
            <ul className="social-media">
              <li>
                <a
                  href="https://www.facebook.com/LaunchPadLab"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/launchpadlab"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/launchpad-lab"
                  target="_blank"
                  rel="noreferrer"
                >
                <img src={linkedin} alt="LinkedIn" />
                </a>
              </li>
              <li>
                <a
                  href="http://instagram.com/launchpadlab"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  className="medium"
                  href="https://medium.com/launchpad-lab"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={medium} alt="Medium" />
                </a>
              </li>
              <li>
                <a
                  className="dribble"
                  href="https://dribbble.com/LaunchPadLab"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={dribbble} alt="Dribbble" />
                </a>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
