import React from 'react'
import ExternalLink from './external-link'
import rocket from '../assets/images/icons/rocket.svg'
import astronaut from '../assets/images/icons/astronaut.svg'

const HireUs = () => (
  <section className="contact-container">
    <div className="contact-block">
      <img alt="" src={rocket} />
      <h2>Hire Us</h2>
      <p>
        Our mission is to help you grow your business with technology. Are you ready to launch something great?
      </p>
      <ExternalLink
        href="https://launchpadlab.com/contact"
        className="button-white-outline"
      >
        Contact Us
      </ExternalLink>
    </div>

    <div className="contact-block">
      <img alt="" src={astronaut} />
      <h2>Join the Crew</h2>
      <p>
        We are looking to add a few more mission critical people to our team. Do you want to be a part of our next big launch?
      </p>
      <ExternalLink
        href="https://launchpadlab.com/careers"
        className="button-white-outline"
      >
        View Openings
      </ExternalLink>
    </div>
  </section>
)

export default HireUs
