import { union } from 'lodash'

const contributorCount = (missions, users = []) => {
  const uniqueMemberNames = missions.reduce((members, { fields: { teamMembers } }) => {
    if (teamMembers) {
      const memberNames = teamMembers.map(({ name }) => name)
      return union(members, memberNames)
    }
    return members
  }, [])
  const uniqueCommanderNames = missions.reduce((members, { fields: { commander } }) => {
    if (commander) return union(members, [commander.name])
    return members
  }, [])

  const contributorNameCount = union(uniqueMemberNames, uniqueCommanderNames).length
  return contributorNameCount || users.length || missions.length
}

export default contributorCount
