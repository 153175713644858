import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import exact from "prop-types-exact"
import { pure } from "recompose"
import { getTime, parse } from "date-fns"
import { get, orderBy, takeWhile } from "lodash"
import classnames from "classnames"
import explorerImage from "../assets/images/explorer.svg"
import {
  DEFAULT_FILTER_TERM,
  MISSION_DISPLAY_BLOCK_SIZE,
  MISSION_TYPE_TABS,
} from "../utils/config"

const propTypes = {
  missions: PropTypes.arrayOf(PropTypes.object),
}
const defaultProps = {}

function MissionControl({ missions }) {
  const [filterTerm, setFilterTerm] = useState(DEFAULT_FILTER_TERM)
  const sortedMissions = useMemo(() => {
    return orderBy(
      missions,
      (mission) =>
        getTime(
          parse(mission.mission_fields.launchDate, "MMMM d, yyyy", new Date())
        ),
      ["desc"]
    )
  }, [missions])
  const filteredMissions = useMemo(() => {
    if (filterTerm === DEFAULT_FILTER_TERM) return sortedMissions
    return sortedMissions.filter(
      (mission) => mission.mission_fields.missionType === filterTerm
    )
  }, [filterTerm, sortedMissions])

  return (
    <section className="mission-block-container">
      <MissionControlPanel
        currentSelection={filterTerm}
        onMissionSelection={setFilterTerm}
      >
        <Missions missions={filteredMissions} />
      </MissionControlPanel>
    </section>
  )
}

MissionControl.propTypes = exact(propTypes)
MissionControl.defaultProps = defaultProps
export default pure(MissionControl)

function MissionControlPanel({
  children,
  currentSelection,
  onMissionSelection,
}) {
  return (
    <>
      <div className="filter-wrapper">
        <div className="button-group filters-button-group">
          {MISSION_TYPE_TABS.map(({ type, title, description }) => {
            return (
              <button
                key={type}
                className={classnames("button1", {
                  "is-checked": currentSelection === type,
                })}
                type="button"
                onClick={() => {
                  onMissionSelection(type)
                }}
              >
                <h2 className="underline-header">{title}</h2>
                <p>{description}</p>
              </button>
            )
          })}
        </div>
      </div>
      {children}
    </>
  )
}

function Missions({ missions }) {
  const [displayLimit, setDisplayLimit] = useState(MISSION_DISPLAY_BLOCK_SIZE)
  const displayedMissions = useMemo(() => {
    return takeWhile(missions, (_mission, index) => index < displayLimit)
  }, [missions, displayLimit])

  return (
    <>
      <ul>
        {displayedMissions.map(
          ({
            slug,
            title,
            mission_fields: { teaser, missionPatch, missionType },
          }) => {
            const patchImage = get(missionPatch, "mediaItemUrl", explorerImage)

            return (
              <li key={slug} className={`element-item ${missionType}`}>
                <a href={`/missions/${slug}`}>
                  <img
                    src={patchImage}
                    alt="mission-patch"
                    className="preview-badge"
                  />
                  <div className="preview-text">
                    <h3>{title}</h3>
                    <p>{teaser}</p>
                  </div>
                </a>
              </li>
            )
          }
        )}
      </ul>
      {displayLimit < missions.length && (
        <button
          onClick={() =>
            setDisplayLimit(displayLimit + MISSION_DISPLAY_BLOCK_SIZE)
          }
          className="button-white-outline centered"
        >
          + Load More
        </button>
      )}
    </>
  )
}
