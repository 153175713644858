import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allWpMenuItem {
        edges {
          node {
            label
            url
          }
        }
      }
    }
  `)

  const menuItems = data.allWpMenuItem.edges.map(({ node }) => node)
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const toggleNav = (e) => {
    e.preventDefault()
    setIsMobileNavOpen(!isMobileNavOpen)
    console.log("nav")
  }

  return (
    <>
      <header aria-label="skip-link">
        <a id="skip-nav-link" href="#main-content">
          Skip to main content
        </a>
      </header>

      <header aria-label="primary navigation area" className="topbar-wrapper">
        <nav
          className="nav-block"
          aria-label="site navigation"
        >
          <div className="left-nav">
            <a href="/">
              <img
                alt="logo"
                src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/space-logo.svg?1593194907"
                className="nav-logo"
              />
            </a>
          </div>
          <div
            className={`right-nav ${
              isMobileNavOpen ? "mobile-menu-active" : ""
            }`}
          >
            <ul>
              <li>
                <a className="home-link" href="/">
                  Home
                </a>
              </li>
              {menuItems.map(({ label, url }) => {
                return (
                  <li key={label}>
                    <a href={`https://launchpadlab.com${url}`}>{label}</a>
                  </li>
                )
              })}
            </ul>
          </div>
        </nav>
      </header>

      <header aria-label="mobile navigation area">
        <nav aria-label="mobile navigation">
          <a
            className={`mobile-nav-trigger ${
              isMobileNavOpen ? "close" : "open"
            }`}
            href="/"
            onClick={toggleNav}
          >
            Menu
          </a>
        </nav>
      </header>
    </>
  )
}

export default Header
