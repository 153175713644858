import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import Slider from 'react-slick'

const propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

function ImageSlider({ images, title, ...rest }) {
  const slider = useRef(null)
  const sliderConfig = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  }

  return (
    <div className="image-slider">
      <div className="title-block">
        <h2>{title}</h2>
        <button
          disabled={images.length === 1}
          onClick={() => slider.current.slickPrev()}
        >
          <MdChevronLeft />
        </button>
        <button
          disabled={images.length === 1}
          onClick={() => slider.current.slickNext()}
        >
          <MdChevronRight />
        </button>
      </div>
      <Slider ref={slider} {...sliderConfig} {...rest}>
        {images.map(({ sourceUrl, alt }) => (
          <div key={sourceUrl}>
            <img src={sourceUrl} alt={alt} />
          </div>
        ))
        }
      </Slider>
    </div>
  )
}

ImageSlider.propTypes = propTypes
export default ImageSlider
