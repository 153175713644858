import React from 'react'
import { Helmet } from 'react-helmet'
import SiteHeader from './header'
import SiteFooter from './footer'

import '../assets/stylesheets/application.scss'

const Layout = ({ children }) => (
  <React.Fragment>
    <Helmet title="SPACE by LaunchPad Lab" defer={false} bodyAttributes={{ class: 'index' }} />
    <SiteHeader />
    <main id="main-content">  
	    {children}
    </main>
    <SiteFooter />
  </React.Fragment>
)

export default Layout
