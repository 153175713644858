import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { useKey } from 'react-use'
import { MdClear } from 'react-icons/md'

const propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

ReactModal.setAppElement('body')

function Modal({ children, isOpen, onClose, ...rest }) {
  useKey('Escape', onClose)

  return (
    <>
      <ReactModal
        bodyOpenClassName="modal-open"
        isOpen={isOpen}
        onRequestClose={onClose}
        overlayClassName="modal-fade-screen"
        portalClassName="modal"
        {...rest}
      >
        <button className="modal-close" onClick={onClose} aria-label="Close">
          <MdClear />
        </button>
        <div className="modal-content" {...rest}>{children}</div>
      </ReactModal>
    </>
  )
}

Modal.propTypes = propTypes
export default Modal
