import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'
import { padStart } from 'lodash'
import {
  COUNTDOWN_ANIMATION_DURATION,
  COUNTDOWN_TRANSFORM_PERSPECTIVE
} from '../utils/config'

const propTypes = {
  time: PropTypes.number,
}
const defaultProps = {
  time: 0,
}

function convertTimeUnits(ms) {
  const seconds = ms / 1000
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = Math.floor(hours / 24)
  const remainingHours = Math.floor(hours % 24)
  const remainingMinutes = Math.floor(minutes % 60)
  const remainingSeconds = Math.floor(seconds % 60)

  const paddedDays = padStart(days, 2, '0')
  const paddedHours = padStart(remainingHours, 2, '0')
  const paddedMinutes = padStart(remainingMinutes, 2, '0')
  const paddedSeconds = padStart(remainingSeconds, 2, '0')

  return {
    daysNum: days,
    days: paddedDays,
    hours: paddedHours,
    minutes: paddedMinutes,
    seconds: paddedSeconds,
  }
}

function CountdownTimer({ time }) {
  const { days, hours, minutes, seconds, daysNum, } = convertTimeUnits(time)
  const inProgress = time < 0 && daysNum <= 0

  return (
    <div className="countdown" role="timer" aria-live="polite">
      {time > 0 && (
        <ul>
          <li>
            <div>
              <CounterDigit aria-labelledby="timeRefDays"digit={days.charAt(0)} />
              <CounterDigit aria-labelledby="timeRefDays"digit={days.charAt(1)} />
              {days.length > 2 && (
                <CounterDigit aria-labelledby="timeRefDays"digit={days.charAt(2)} />
              )}
            </div>
            <p id="timeRefDays">Days</p>
          </li>
          <li>
            <div>
              <CounterDigit aria-labelledby="timeRefHours"digit={hours.charAt(0)} />
              <CounterDigit aria-labelledby="timeRefHours"digit={hours.charAt(1)} />
            </div>
            <p id="timeRefHours">Hours</p>
          </li>
          <li>
            <div>
              <CounterDigit aria-labelledby="timeRefMinutes"digit={minutes.charAt(0)} />
              <CounterDigit aria-labelledby="timeRefMinutes"digit={minutes.charAt(1)} />
            </div>
            <p id="timeRefMinutes">Minutes</p>
          </li>
          <li>
            <div>
              <CounterDigit aria-labelledby="timeRefSeconds"digit={seconds.charAt(0)} />
              <CounterDigit aria-labelledby="timeRefSeconds"digit={seconds.charAt(1)} />
            </div>
            <p id="timeRefSeconds">Seconds</p>
          </li>
        </ul>
      )}
      {inProgress &&
        <div className="live-demo-day">
          <h3>In Progress</h3>
          <img alt="Starfield" className="starfield" src="https://space-station.s3.amazonaws.com/sites/566b5aa0d7bcec0003000001/theme/images/starfield.gif?1593194907" />
        </div>
      }
    </div>
  )
}

CountdownTimer.propTypes = propTypes
CountdownTimer.defaultProps = defaultProps
export default CountdownTimer

function CounterDigit({ digit }) {
  const [displayedDigit, setDisplayedDigit] = useState(digit)
  const top = useRef(null)
  const topBack = useRef(null)

  useEffect(() => {
    const topRef = top.current
    const topBackRef = topBack.current

    if (topRef && topBackRef) {
      gsap.to(
        topRef,
        {
          duration: COUNTDOWN_ANIMATION_DURATION,
          rotationX: '-180deg',
          transformPerspective: COUNTDOWN_TRANSFORM_PERSPECTIVE,
          ease: 'power3',
          onComplete: () => gsap.set(topRef, { rotationX: 0 }),
        }
      )
      gsap.to(
        topBackRef,
        {
          duration: COUNTDOWN_ANIMATION_DURATION,
          rotationX: 0,
          transformPerspective: COUNTDOWN_TRANSFORM_PERSPECTIVE,
          ease: 'power3',
          clearProps: 'all',
        }
      )
      setDisplayedDigit(digit)
    }
  }, [digit])

  return (
    <div className="figure">
      <span className="top" ref={top}>{displayedDigit}</span>
      <span className="top-back" ref={topBack}>
        <span>{displayedDigit}</span>
      </span>
      <span className="bottom">{displayedDigit}</span>
      <span className="bottom-back">
        <span>{displayedDigit}</span>
      </span>
    </div>
  )
}
