export const COUNTDOWN_ANIMATION_DURATION = 0.8
export const COUNTDOWN_TRANSFORM_PERSPECTIVE = 300
export const DEFAULT_FILTER_TERM = 'All'
export const DEMO_DAYS_DISPLAY_BLOCK_SIZE = 5
export const MISSION_DISPLAY_BLOCK_SIZE = 9
export const MISSION_TYPE_TABS = [
  { type: 'All', title: 'All Missions', description: 'Each mission allows us to learn and experiment with something new. ' },
  { type: 'Flyby', title: 'Flybys', description: 'Experimental. Fun. Quick hacks or demos.' },
  { type: 'Rocket', title: 'Rockets', description: 'Efficient enhancements. Plugins. Gems. APIs.' },
  { type: 'Satellite', title: 'Satellites', description: 'Products and businesses built to last.' },
]
